<template>
    <parent-layout title="User Management">
        <template #actions>
            <v-btn
                :to="{ name: 'staff-user-create' }"
                :disabled="!hasCreateRole"
                class="primary ml-4"
                >Add Staff
            </v-btn>
        </template>
        <v-tabs class="mb-4">
            <v-tab link :to="{ name: 'user-list' }">User List</v-tab>
            <v-tab link :to="{ name: 'account-sync-logs' }">User Sync Errors</v-tab>
        </v-tabs>
        <router-view />
    </parent-layout>
</template>

<script>
import ColumnDef from '@/helpers/ag-grid/columnDef/staffUserManagement'
import { ROLE_USER_MANAGEMENT_CREATE } from '@/helpers/security/roles'
import ParentLayout from '@/components/layout/ParentLayout'
import { mapGetters } from 'vuex'

export default {
    components: {
        ParentLayout,
    },
    data: () => ({
        columnDefs: ColumnDef,
        gridApi: {},
    }),
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            districtId: 'getDistrictId',
            lea: 'getDistrictLea',
        }),
        hasCreateRole() {
            return this.hasRole(ROLE_USER_MANAGEMENT_CREATE)
        },
    },
}
</script>
